<template>
  <div class="card card-custom card-stretch gutter-b">
    <b-row v-if="showError">
        <b-col lg="12">
            <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
            <b-alert show variant="secondary">Skapa faktureringsunderlag genom att sätta fakturanummer och fakturadatum på transaktioner som inte ännu har någon faktura satt</b-alert>
        </b-col>
    </b-row>

    <b-row class="mt-8">
        <b-col lg="6">
            <b-form-group id="input-group-created_from" label="Transaktionsdatum Från" label-for="input-created_from">
              <memlist-date-picker v-model="created_from"></memlist-date-picker>
            </b-form-group>
        </b-col>
        <b-col lg="6">
            <b-form-group id="input-group-created_to" label="Transaktionsdatum Till" label-for="input-created_to">
              <memlist-date-picker v-model="created_to"></memlist-date-picker>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>

            <b-form-group id="input-group-service" label="Tjänst" label-for="input-service">
              <TransactionServicesSelect
                ref="serviceSelect"
                @onSelectionChanged="onServiceSelectionChanged"
              />
            </b-form-group>

        </b-col>
        <b-col>
            <b-form-group id="input-group-module" label="Modul" label-for="input-module">
              <TransactionModulesSelect
                ref="modulesSelect"
                @onSelectionChanged="onModuleSelectionChanged"
              />
            </b-form-group>
        </b-col>
    </b-row>

    <b-row>
        <b-col cols="2" class="justify-content-end">

            <b-button style="margin-top: 25px;"  variant="success" :disabled="countInputValid" @click="calcCountClicked">
                Beräkna
            </b-button>

        </b-col>
        <b-col cols="5">
            <b-form-group label="Antal transaktioner" label-for="person-count_transactions">
                <b-form-input autocomplete="off"
                id="count_transactions"
                v-model="count_transactions"
                disabled
                />
            </b-form-group>
        </b-col>
        <b-col cols="5">
            <b-form-group label="Kostnad" label-for="person-amount_transactions">
                <b-form-input autocomplete="off"
                id="amount_transactions"
                v-model="amount_transactions"
                disabled
                />
            </b-form-group>
        </b-col>

    </b-row>


    <b-card
      title="Fakturakoppling"
    >
        <b-row>
        <b-col>
            <b-form-group label="Fakturanummer" label-for="person-ths_invoice_nr">
                <b-form-input autocomplete="off"
                id="ths_invoice_nr"
                v-model="ths_invoice_nr"
                :state="ths_invoice_nr_valid"
                placeholder="Siffra"
                />
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Fakturadatum" label-for="person-ths_invoiced_at">
              <memlist-date-picker
                v-model="ths_invoiced_at"
                :state="ths_invoiced_at_valid"
              ></memlist-date-picker>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row class="mt-6">
        <b-col lg="6">
        <b-button variant="primary" :disabled="inputValid" @click="save">
            Skapa underlag
        </b-button>
        </b-col>
    </b-row>
    </b-card>

      <!--end::Table-->
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ExportTHSTransactionsModal from '@/view/pages/ml/transactions/ExportTHSTransactionsModal.vue';
import TransactionServicesSelect from '@/view/pages/ml/transactions/TransactionServicesSelect.vue';
import TransactionModulesSelect from '@/view/pages/ml/transactions/TransactionModulesSelect.vue';
import dayjs from 'dayjs';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';



export default {
  name: "transactions-ths-admin-view",
  components: {
    ExportTHSTransactionsModal,
    TransactionServicesSelect,
    TransactionModulesSelect,
  },
  emits: ['createTransactionClicked', 'selectTransactionClicked', 'deleteTransactionClicked'],
  watch: {

  },
  mixins: [ toasts ],
  computed:{
    ...mapGetters(['isTHS']),
    countInputValid() {
      return !(this.created_from !== null && this.created_to !== null && this.services.length > 0 && this.modules.length > 0);
    },
    ths_invoiced_at_valid() {
      return this.ths_invoiced_at !== null;
    },
    ths_invoice_nr_valid() {
      const test = parseInt(this.ths_invoice_nr+'');

      if (isNaN(test)) {
        return false;
      }

      return true;
    },
    inputValid() {
      return !(this.created_from !== null && this.created_to !== null && this.services.length > 0 && this.modules.length > 0 && this.ths_invoiced_at_valid && this.ths_invoice_nr_valid );
    }
  },
  mounted() {
    this.created_from = dayjs().startOf('month').format('YYYY-MM-DD');
    this.created_to = dayjs().endOf('month').format('YYYY-MM-DD');
  },
  methods: {
    onServiceSelectionChanged(services) {
      this.services = services;
    },
    onModuleSelectionChanged(modules) {
      this.modules = modules;
    },
    calcCountClicked() {

      const loader = this.$loading.show();

      const body = {
        modules: this.modules,
        services: this.services,

        from_date: this.created_from,
        to_date: this.created_to
      }

      axios
        .post(`/transaction/count`, body)
        .then(res => {
          this.count_transactions = res.data.count;
          this.amount_transactions = res.data.amount;
          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte beräkna antal');
          loader && loader.hide();
        });
    },
    createTHSInvoicingClicked() {
      this.$refs['exportTHSTransactions'].show();
    },
    createTransactionClicked() {
      this.$emit('createTransactionClicked');
    },
    selectTransactionClicked(id) {
      this.$emit('selectTransactionClicked', id);
    },
    deleteTransactionClicked(id) {
      this.$emit('deleteTransactionClicked', id);
    },

    save() {
      const body = {
        modules: this.$refs['modulesSelect'].getData(),
        services: this.$refs['serviceSelect'].getData(),

        from_date: this.created_from,
        to_date: this.created_to,

        ths_invoice_nr: parseInt(this.ths_invoice_nr+''),
        ths_invoiced_at: this.ths_invoiced_at
      }

      this.postXlsx(body);
    },

    postXlsx(body) {
      const loader = this.$loading.show();

      axios
        .post('/transaction/xlsx', body)
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
          else {
            console.error(res.data);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda hem export');
          }
          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa export');
          loader && loader.hide();
        });
    },

  },
  data() {
    return {
      count_transactions: 0,
      amount_transactions: 0,
      showError: false,
      created_from: null,
      created_to: null,
      ths_invoice_nr: null,
      ths_invoiced_at: null,
      modules: [],
      services: [],
      showAll: false,
      currentPage: 1,
      perPage: 100,
      options: [
        100, 150, 200
      ],
      headers: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'reference',
          label: 'Referens',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'created_at',
          label: 'Datum',
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
        {
          key: 'company_id',
          label: 'Hantera',
          thClass: 'w-120px text-right pr-12 align-middle',
        },
      ]
    };
  }
};
</script>
