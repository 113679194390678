<template>
  <b-card>
    <b-table
        id="modules-table"
        :fields="headers"
        :items="moduleOptions"
        thead-class="d-none"
      >
        <template #cell(id)="row">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                v-model="row.item.selected"
                :checked="Boolean(row.item.selected)"
                @change="onChanged"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer">{{row.item.name}}</span>
          </div>
        </template>
    </b-table>
    <b-row>
        <b-col>
            <div class="d-flex align-items-center ml-7 ">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                    type="checkbox"
                    name=""
                    v-model="all"
                    />
                    <span></span>
                </label>
                <span @click="all = !all" class="ml-3 cursor-pointer">Alla</span>
            </div>
        </b-col>
    </b-row>

  </b-card>
</template>


<style scoped>
</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
  name: 'modules-select',
  components: {
    
  },
  emits: ['onSelectionChanged'],
  data() {
    return {
      all: false,
      moduleOptions: [{ id: 0, selected: false, name: this.$t('COMMON.ALL') }],
      headers: [
        {
          key: 'id',
          label: 'Vald',
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        }
      ],
      modules: []
    };
  },
  watch: {
    all(newValue) {
      for (const p of this.moduleOptions) {
        p.selected = newValue;
      }

      this.$emit('onSelectionChanged', this.getData());
    }
  },
  methods: {
    onChanged() {
      this.$emit('onSelectionChanged', this.getData());
    },
    loadModules() {
      this.modules = [
        { id: 'REGISTER', name: 'Registrering' },
        { id: 'RENEW', name: 'Förnyelse' },
        { id: 'SENDOUT', name: 'Utskick' },
        { id: 'EVENT', name: 'Evenemang' },
        { id: 'SETTLEMENT', name: 'Generell' }
      ];

      this.moduleOptions = this.modules.map(item => ({ id: item.id, name: item.name, selected: false }));

    },
    getData() {

      const ret = [];

      for (const p of this.moduleOptions) {
        if (!p.selected) {
          continue;
        }
        ret.push(p.id);
      }

      return ret;
    },
    reset() {
      this.all = false;
    },
    setModules(module_ids) {
      if (!module_ids) {
        this.reset();
        return;
      }

      for (const p of module_ids) {
        const pO = this.moduleOptions.find(item => item.id === p);

        if (pO) {
          pO.selected = true;
        }
      }

    },
  },
  mounted() {

    this.loadModules();
  }
};
</script>
