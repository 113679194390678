<template>
    <b-card>
        <b-row v-if="isNonInvoiced">
            <b-col lg="6">
                <b-form-group id="input-group-from_date" label="Transaktionsdatum Från" label-for="input-from_date">
                  <memlist-date-picker v-model="from_date"></memlist-date-picker>
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group id="input-group-to_date" label="Transaktionsdatum Till" label-for="input-to_date">
                  <memlist-date-picker v-model="to_date"></memlist-date-picker>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="!isNonInvoiced">
            <b-col>
                <b-form-group label="Fakturanummer" label-for="form-invoice-nr">
                    <b-form-select
                    id="form-invoice-nr"
                    :options="invoiceOptions"
                    v-model="ths_invoice_nr"
                    />
                </b-form-group>
            </b-col>
            <b-col>

            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button variant="success" @click="searchClicked">
                    Sök
                </b-button>
            </b-col>
        </b-row>

    </b-card>
</template>
<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import dayjs from 'dayjs';


export default {
  name: "transaction-search-options",
  components: {
    
  },
  props: ['isNonInvoiced'],
  emits: ['onSearch'],
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS']),
  },
  mounted() {
    if (!this.isNonInvoiced) {
      this.loadInvoices();
    }

    this.from_date = dayjs().startOf('month').format('YYYY-MM-DD');
    this.to_date = dayjs().endOf('month').format('YYYY-MM-DD');
  },
  methods: {
    searchClicked() {
      if (this.isNonInvoiced) {
        this.$emit('onSearch', this.from_date, this.to_date);
      }
      else {
        this.$emit('onSearch', this.ths_invoice_nr);
      }

    },
    loadInvoices() {
      axios
        .get(`/transaction/thsinvoices`)
        .then(res => {
          this.invoiceOptions = res.data.map(item => ({ value: item.ths_invoice_nr, text: item.ths_invoice_nr + ' (' + item.ths_invoiced_at + ')' }));
        })
        .catch(err => {
          console.error(err);
        })
    }
  },
  data() {
    return {
      invoiceOptions: [],
      ths_invoice_nr: null,
      from_date: null,
      to_date: null
    };
  }
};
</script>
