<template>
  <div class="px-5 mt-8" id="page-bank">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <b-form class="mt-8" @submit.prevent="saveData">
            <b-form-group class="col-4" id="input-group-bg_pct" label="Bankgiro Procent" label-for="input-bg_pct">
              <b-form-input
                id="input-bg_pct"
                v-model="billing.bg_pct"
                type="text"
              ></b-form-input>
              <b-form-text>procent / 100, 1% = 0.01</b-form-text>
            </b-form-group>
            <b-form-group class="col-4" id="input-group-dintero_pct" label="Dintero Procent" label-for="input-dintero_pct">
              <b-form-input
                id="input-dintero_pct"
                v-model="billing.dintero_pct"
                type="text"
              ></b-form-input>
              <b-form-text>procent / 100, 1% = 0.01</b-form-text>
            </b-form-group>
            <b-form-group class="col-4" id="input-group-manual_payment_pct" label="Manuell Betalning Procent" label-for="input-manual_payment_pct">
              <b-form-input
                id="input-manual_payment_pct"
                v-model="billing.manual_payment_pct"
                type="text"
              ></b-form-input>
              <b-form-text>procent / 100, 1% = 0.01</b-form-text>
            </b-form-group>

            <b-button type="submit" variant="primary">Spara</b-button>
          </b-form>
        </b-card></b-col
      >
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'education-settings',
  props: ['settings'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      billing: {
        bg_pct: 0.01,
        dintero_pct: 0.027,
        manual_payment_pct: 0.027
      }
    };
  },
  created(){
    if (this.settings && this.settings.billing){
      this.billing = this.settings.billing;
    }
  },
  methods: {

    parseFloatData() {
      this.billing.bg_pct = parseFloat(this.billing.bg_pct+'');
      this.billing.dintero_pct = parseFloat(this.billing.dintero_pct+'');
      this.billing.manual_payment_pct = parseFloat(this.billing.manual_payment_pct+'');
    },

    saveData() {
      const loader = this.$loading.show();
      
      this.parseFloatData();
      axios.put(`/company/company_settings/${this.currentCompanyId}`, {
        billing: this.billing
      }).then(res => {
        if (res.status === 200){
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
        loader.hide();
      }).catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        loader.hide();
      })
    },
    
  }
};
</script>
